import {createRouter, createWebHistory} from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=>import('@/views/home/homePage'),
    meta: {title: '运营业务中台'}
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/loginPage')
  },
  {
    path: '/platform-settlement/management-center',
    name: 'platform-settlement',
    component: () => import('@/views/platform-settlement/managementCenter')
  },
  {
    name: 'e-commerce-transactions',
    path: '/e-commerce-transactions/management-center',
    component: () => import('@/views/e-commerce-transactions/managementCenter'),
  },
  {
    name: 'e-commerce-transactions/allTableData',
    path: '/e-commerce-transactions/allTableData',
    component: () => import('@/views/e-commerce-transactions/allTableData')
  },
  {
    name: 'e-commerce-transactions/couponDetail',
    path: '/e-commerce-transactions/couponDetail',
    component: () => import('@/views/e-commerce-transactions/couponDetail')
  },
  {
    name: 'e-commerce-transactions/dataManagementCenter',
    path: '/e-commerce-transactions/dataManagementCenter',
    component: () => import('@/views/e-commerce-transactions/dataManagementCenter')
  },
  {
    name: 'convenient-services/managementCenter',
    path: '/convenient-services/managementCenter',
    component: () => import('@/views/convenient-services/managementCenter')
  },
  {
    name: 'convenient-services/dataManagementCenter',
    path: '/convenient-services/dataManagementCenter',
    component: () => import('@/views/convenient-services/dataManagementCenter')
  },
  {
    name: 'user-data-management-center/centerIndex',
    path: '/user-data-management-center/centerIndex',
    component: () => import('@/views/user-data-management-center/centerIndex')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta.title
  } else {
    document.title = '运营业务中台'
  }
  if (to.path !== '/login') {
    const token = sessionStorage.getItem('token')
    if (!token) {
      router.replace('/login') 
    }
  }
  next();
})

export default router