<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

</style>
