import { createApp } from 'vue'
import App from './App.vue'
import './utils/rem.js'
import router from './router'
import DataVVue3 from '@kjgl77/datav-vue3';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'dayjs/locale/zh-cn'

createApp(App).use(router).use(DataVVue3).use(ElementPlus, {
  locale: zhCn
}).mount('#app')
