(function() {
  const recalCulate = function() {
    const dpr = window.devicePixelRatio
    const currentWidth = document.documentElement.clientWidth
    let remSize = 0
    let scale = 0
    const baseWidth = 1920
    scale = currentWidth / baseWidth
    remSize = baseWidth / 10
    remSize = remSize * scale
    document.documentElement.style.fontSize = remSize + "px"
    document.documentElement.setAttribute("data-dpr", `${dpr}`)
  }
  recalCulate()
  if (!document.addEventListener) return
  const resizeEvent = "orientationchange" in window ? "orientationchange" : "resize"
  window.addEventListener(resizeEvent, recalCulate, false)
})()
